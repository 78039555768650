/*@import-normalize;*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  background: #666;
}
h1 {
  margin-top: 0;
}


#root {
  box-sizing: border-box;
  background: #fff;
  height: 98vh;
  margin: 1vh auto;
  padding: 1em;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
}


/* For extremely small screen devices (595px and below) */
@media only screen and (max-width: 595px) {
  #root {
    max-width: 97vw;
    /*
    background: #f00;
    */
  }
}

/* Small screen devices (600px and above) */
@media only screen and (min-width: 600px) {
  #root {
    /*background: #0f0;*/
    max-width: 97vw;
  }
}

/* Medium screen devices (768px and above) */
@media only screen and (min-width: 768px) {
  #root {
    /*background: #00f;*/
    max-width: 70vw;
  }
}

/* Big screen devices (889px and above) */
@media only screen and (min-width: 889px) {
  #root {
    /*background: #ff0;*/
    max-width: 60vw;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
  #root {
    /*background: #0ff;*/
    max-width: 50vw;
  }
}

/* Extra big screen devices (1200px and above) */
@media only screen and (min-width: 1200px) {
  #root {
    /*background: #fff;*/
    min-height: 98vh;
    max-width: 40vw;
  }
}