.flash {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
}

.flash .card {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;

  display: flex;
  flex-direction: column;
}

.flash .controls {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 4em;
}

.flash .card .flip-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.flash .card .front {
  flex-grow: 1;
  flex-shrink: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.flash .controls button {
  width: 25%;
  height: 100%;
}


.flash .card .flip-card {
  position: relative;
  text-align: center;
  transition: transform 0.4s;
  transform-style: preserve-3d;
}

.flash .card.flipped .flip-card {
  transform: rotateY(180deg);
}

.flash .card .front,
.flash .card .back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  cursor: pointer;
}

.flash .card .back {
  transform: rotateY(180deg);
}

.flash .card .back .data {
  text-align: left;
  box-sizing: border-box;
  padding: 2em;
  border: 4px solid #000;
  background: #ddd;
  height: calc(100% - 2.5em);
  aspect-ratio: 555/970;
  margin: 1em auto;
  overflow-y: auto;
}

.flasher {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 0.2s;
  opacity: 0.2;
}

.flasher.red {
  background: #f77;
  z-index: 100;
}

.flasher.green {
  background: #7f7;
  z-index: 100;
}

.flasher.gray {
  background: #aaa;
  z-index: 100;
}


/*


.swiper {
  transition: all 0.2s ease-out;
}
.swiper.outleft {
  transform: translateX(-100%);
}
.swiper.outright {
  transform: translateX(100%);
}



.flash .card .front img {
  max-height: 80vh;
}

.flash .controls {
  height: 10%;
}

.controls {
}

.controls button {
    width: 25%;
    height: 100%;
}
*/