
.lookup h1 {
    text-align: center;
    margin-bottom: 0;
}


.cardlist.expanded button {
    width: 20%;
}

.cardgroups {
    margin: 0 0 1em 0;
}



.cardgroups button {
    width: 20%;
}


.cardlist {
    overflow-y: auto;
}
.cardlist h1 {
    text-align: center;
}

.cardlist.collapsed button .image {
    display: none;
}
.cardlist.collapsed button {
    width: 12.5%;
}
.cardlist.expanded button .symbol {
    display: none;
}

.cardinfo {
    overflow-y: auto;
}
.cardinfo .cardcols {
    display: flex;
}
.cardinfo .cardcols .left {
    width: 40%;
}
.cardinfo .cardcols .right {
    width: 60%;
}
.cardinfo img {
    width: 100%;
}



.lookup button .image.square {
    aspect-ratio: 1;
    overflow: hidden;
    margin: 4px;
    border: 1px solid #000;
}

.lookup button .image {
    margin: 4px;
}

.lookup button img {
    box-sizing: border-box;
    max-width: 100%;
}
.lookup button.active {
    background: #ea2;
}