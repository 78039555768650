.menu .buttonGroup {
    text-align: center;
}

.menu button {
    padding: 1em;
    cursor: pointer;
    margin: 0.5em;
}

.menu button.big {
    font-size: large;
}





.menubar {
    flex-basis: 3em;
    flex-grow: 0;
    flex-shrink: 0;
}
.menubar button {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

button.danger {
    color: #922;
    font-weight: bold;
}